.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.container {
  flex: 1;
  overflow: overlay;
  display: flex;
}
.grid {
  height: 100%;
}
.logo {
  width: 340px;
}
.form {
  width: 380px;
  max-width: "100%";
}
.authenticatorWrapper {
  display: flex;
  align-items: center;
  min-height: 483px;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.right {
  background-color: #3aa9ae;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 60px;
}
.flex {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.appInfo {
  height: 483px;
}
.link {
  cursor: pointer;
}
.button {
  color: #ffffff;
  box-shadow: none !important;
  margin-bottom: 16px !important;
}
.sso-button {
  background-color: #047d95;
  box-shadow: none !important;
  color: #ffffff;
}
.sso-button:hover {
  background-color: #005566;
  color: #ffffff;
}
.forgetPasswordFooter {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
}
.signUpFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}
.confirmSignUpFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerSecondaryContent {
  color: #828282;
  font-size: 14px;
  margin-top: 24px;
  text-align: center;
}
.formField {
  margin-bottom: 24px;
}
.label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #152939;
}
.input {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #152939;
  border-radius: 3px;
  box-sizing: border-box;
  border-radius: 4;
}

.forgetPassword {
  color: #828282;
  font-size: 12px;
  margin-bottom: 24px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.singInLink {
  color: #828282;
  font-size: 12px;
}
.appTitle {
  font-weight: 300 !important;
  /* font-size: 35px !important; */
}
.environment {
  font-size: 27px !important;
  text-transform: uppercase;
  font-weight: bold !important;
}
.appDescription {
  margin-top: 24px !important;
  font-size: 30px !important;
  font-weight: 300 !important;
}
.amplify-tabs {
  display: none;
}
[data-amplify-authenticator] {
  position: inherit;
  width: 100%;
  background-color: #ffffff;
}
[data-amplify-authenticator] [data-amplify-container] {
  width: 100%;
}
[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}
.amplify-field-group__outer-start,
.amplify-field-group__outer-end {
  display: none;
}
[data-amplify-form][data-amplify-authenticator-signup] > fieldset > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

[data-amplify-form][data-amplify-authenticator-signup]
  fieldset
  div:nth-child(n + 3) {
  grid-column-start: 1;
  grid-column-end: 3;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #767676;
  padding: 4px;
}
.imprint-link {
  font-size: 12px;
  color: #ffffff;
  text-decoration: none;
  margin-right: 8px;
}
.copyright {
  font-size: 14px;
}
.closeIcon {
  color: #ffffff;
  cursor: pointer;
}
