html {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.navigation-hover-element .navigation-visible-on-hover {
  display: none;
}

.navigation-hover-element:hover .navigation-visible-on-hover {
  display: block;
}

a[class^="Toast__toastClose"] {
  margin-left: auto;
  align-self: center;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  cursor: pointer;
}

a[class^="Toast__toastClose"]:after,
a[class^="Toast__toastClose"]:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #c4c4c4;
}

a[class^="Toast__toastClose"]:before {
  transform: rotate(45deg);
}

a[class^="Toast__toastClose"]:after {
  transform: rotate(-45deg);
}
